import React, { useState, useEffect } from "react";
import { auth } from "../utils/firebase";
import BannerAd from "../components/BannerAd";
const { version } = require("../../package.json");

const AboutPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const currentUser = auth.currentUser;
    document.title = "About - AfterCreds";
    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content = "Learn more about AfterCreds and its features.";
    document.head.appendChild(metaDescription);

    if (currentUser) {
      setEmail(currentUser.email); // Auto-populate email field for logged-in users
      setIsUserLoggedIn(true);
    }

    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  return (
    <div className="font-custom bg-gray-200 dark:bg-gray-900 min-h-screen pt-20 pb-20 sm:pb-0">
      <div className="container mx-auto p-8">
        <h1 className="text-3xl font-semibold mb-6 text-gray-900 dark:text-white">
          About
        </h1>
        <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-300 dark:border-gray-600 shadow-lg overflow-hidden w-full">
          <div className="sm:pl-8 sm:p-4 p-4">
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Have you ever stuck around after a movie waiting to see if there
              was a secret scene at the end of the credits?
            </p>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Well, AfterCreds.com is a project created with the intention of
              making it easier to find information regarding movie scenes hiding
              in credits, that way you'd know if you should stick around in
              advance. Simply load up AfterCreds.com, find the movie you're
              watching (or planning to), and you'll be able to see whether that
              movie has a mid-credits scene, or an end-credits scene.
            </p>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              AfterCreds.com also offers tools for those who have an account,
              allowing users to customize their preferences, favorite movies to
              a list, and more features to come.
            </p>
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              This project utilizes the TMDB API for general movie information.
              In addition to TMBD, we utilize our very own custom API to provide
              information regarding movie credit scenes. Our API is a work in
              progress, so there may be movies that you find don't yet have any
              information regarding scenes.
            </p>
            <p className="text-gray-700 dark:text-gray-300 text-end">
              - AfterCreds, Josh
            </p>
          </div>
        </div>

        {/* Feedback Section */}
        <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-300 dark:border-gray-600 shadow-lg overflow-hidden w-full mt-8 p-6">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
            Feedback
          </h2>

          <form action="https://formspree.io/f/mlderoje" method="POST">
            <div className="mb-4">
              <label
                className="block text-gray-700 dark:text-gray-300 font-semibold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full py-2 px-6 rounded-lg
      text-gray-700 dark:text-gray-200
      border border-gray-300 dark:border-gray-600 
      bg-white dark:bg-gray-700 
      focus:outline-none focus:ring-4 focus:ring-green-300
      transition-all duration-300 ease-in-out shadow-md
      placeholder-gray-400"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isUserLoggedIn} // Disable for logged-in users
                required
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 dark:text-gray-300 font-semibold mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="w-full py-2 px-6 rounded-lg
      text-gray-700 dark:text-gray-200
      border border-gray-300 dark:border-gray-600 
      bg-white dark:bg-gray-700 
      focus:outline-none focus:ring-4 focus:ring-green-300
      transition-all duration-300 ease-in-out shadow-md
      placeholder-gray-400"
                rows="5"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>

            <div className="flex justify-center mt-6">
              <button
                type="submit"
                className="
      w-full max-w-xs py-2 px-6 rounded-lg
      text-white text-lg font-semibold 
      bg-gradient-to-r from-green-600 to-green-500
      hover:from-green-500 hover:to-green-400
      active:from-green-700 active:to-green-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-green-300
    "
              >
                Send
              </button>
            </div>
          </form>
        </div>

        {/* Web App Version */}
        <div className="block text-center text-sm text-gray-500 dark:text-gray-400 mt-4">
          Version {version}
        </div>
      </div>
      <BannerAd />
    </div>
  );
};

export default AboutPage;
