// src/components/Footer.js
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-4">
      <div className="container mx-auto text-center">
        <p>&copy; 2024 AfterCreds</p>
        <Link to="/terms-of-service" className="text-green-400">
          Terms of Service
        </Link>{" "}
        |{" "}
        <Link to="/privacy-policy" className="text-green-400">
          Privacy Policy
        </Link>{" "}
        |{" "}
        <Link to="/cookie-policy" className="text-green-400">
          Cookie Policy
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
