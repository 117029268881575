// src/App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import MoviePage from "./pages/MoviePage";
import Account from "./components/Account";
import Favorites from "./pages/FavoritesPage";
import UpdatePassword from "./pages/UpdatePassword";
import AdminPage from "./pages/AdminPage";
import AboutPage from "./pages/AboutPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import CookiePolicy from "./pages/CookiePolicy";
import Footer from "./components/Footer";
import { useState } from "react";
import { db } from "./utils/firebase"; // Firestore instance
import { doc, setDoc } from "firebase/firestore"; // Firestore methods
import GoogleAnalytics from "./components/GoogleAnalytics"; // Google Analytics component
import CookieConsent from "./components/CookieConsent";

function App() {
  const [searchTerm, setSearchTerm] = useState("");
  const [adsEnabled, setAdsEnabled] = useState(true); // Toggle ads

  // Function to update ad status in Firestore
  const updateAdStatus = async (newStatus) => {
    const docRef = doc(db, "settings", "ads");
    await setDoc(docRef, { enabled: newStatus }); // Update 'enabled' field in Firestore
    setAdsEnabled(newStatus); // Update local state
  };

  return (
    <Router>
      <GoogleAnalytics />
      <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <CookieConsent />
      <Routes>
        <Route path="/" element={<Home searchTerm={searchTerm} />} />
        <Route path="/account" element={<Account />} />
        <Route path="/movie/:title/:movieId" element={<MoviePage />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/update-password" element={<UpdatePassword />} />
        <Route
          path="/admin"
          element={
            <AdminPage setAdsEnabled={updateAdStatus} adsEnabled={adsEnabled} />
          }
        />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "}
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="cookie-policy" element={<CookiePolicy />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
