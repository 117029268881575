import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../utils/firebase";

const BannerAd = () => {
  const [adsEnabled, setAdsEnabled] = useState(false);
  const [adsLoaded, setAdsLoaded] = useState(false);
  const [isUSUser, setIsUSUser] = useState(false);
  const [showAds, setShowAds] = useState(false); // New state to control showing ads

  useEffect(() => {
    const fetchAdStatus = async () => {
      try {
        const docRef = doc(db, "settings", "ads");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setAdsEnabled(data.enabled);
        }
      } catch (error) {
        console.error("Error fetching ad status:", error);
      }
    };

    const checkUserRegion = () => {
      fetch("https://ipapi.co/country/")
        .then((response) => response.text())
        .then((countryCode) => {
          if (countryCode === "US") {
            setIsUSUser(true); // U.S. user detected
            setShowAds(true); // Show ads immediately for U.S. users
          }
        })
        .catch((error) => console.error("Error detecting country: ", error));
    };

    fetchAdStatus();
    checkUserRegion();

    const handleAdsConsent = () => {
      const consent = localStorage.getItem("cookieConsent");
      if (adsEnabled && (isUSUser || consent === "true")) {
        setShowAds(true); // Show ads for U.S. users or EU users who accepted cookies
        if (!adsLoaded) {
          try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
            setAdsLoaded(true); // Avoid multiple loads
          } catch (error) {
            console.error("AdSense error:", error);
          }
        }
      }
    };

    window.addEventListener("adsConsentGranted", handleAdsConsent);

    // Immediately load ads for U.S. users or based on consent
    handleAdsConsent();

    return () => {
      window.removeEventListener("adsConsentGranted", handleAdsConsent);
    };
  }, [adsEnabled, adsLoaded, isUSUser]);

  if (!adsEnabled || (!showAds && !isUSUser)) {
    return null; // Do not display ads if cookies are declined or ads are disabled
  }

  return (
    <div className={`ads-container ${!showAds ? "hidden" : ""}`}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-4530213491042233"
        data-ad-slot="9624082539"
        data-ad-format="auto"
      ></ins>
    </div>
  );
};

export default BannerAd;
