import React, { useState, useEffect } from "react";

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [isUSUser, setIsUSUser] = useState(false);

  useEffect(() => {
    const isEUCountries = () => {
      const EU_COUNTRIES = [
        "AT",
        "BE",
        "BG",
        "CY",
        "CZ",
        "DE",
        "DK",
        "EE",
        "ES",
        "FI",
        "FR",
        "GR",
        "HR",
        "HU",
        "IE",
        "IT",
        "LT",
        "LU",
        "LV",
        "MT",
        "NL",
        "PL",
        "PT",
        "RO",
        "SE",
        "SI",
        "SK",
      ];
      fetch("https://ipapi.co/country/")
        .then((response) => response.text())
        .then((countryCode) => {
          if (countryCode === "US") {
            setIsUSUser(true);
          } else if (EU_COUNTRIES.includes(countryCode)) {
            const consent = localStorage.getItem("cookieConsent");
            if (!consent) {
              setShowBanner(true);
            }
          }
        })
        .catch((error) => console.error("Error detecting country: ", error));
    };

    isEUCountries();
  }, []);

  const handleConsent = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowBanner(false);

    const event = new Event("adsConsentGranted");
    window.dispatchEvent(event);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "false");
    setShowBanner(false);
  };

  if (isUSUser) {
    return null;
  }

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white py-4 px-6 shadow-lg z-50">
      <p className="mb-4 text-center">
        This site uses cookies to enhance user experience and analyze
        performance. By using this site, you agree to the use of cookies.{" "}
        <a href="/cookie-policy" className="underline">
          Learn more.
        </a>
      </p>
      <div className="flex justify-center space-x-4">
        <button
          onClick={handleDecline}
          className="
            w-28 py-2 px-4 rounded-lg
            text-white text-md font-semibold
            bg-gradient-to-r from-red-600 to-red-500
            hover:from-red-500 hover:to-red-400
            active:from-red-700 active:to-red-600
            transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
            shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-red-300
          "
        >
          Decline
        </button>
        <button
          onClick={handleConsent}
          className="
            w-28 py-2 px-4 rounded-lg
            text-white text-md font-semibold
            bg-gradient-to-r from-green-600 to-green-500
            hover:from-green-500 hover:to-green-400
            active:from-green-700 active:to-green-600
            transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
            shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-green-300
          "
        >
          Accept
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;
