// src/utils/auth.js
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  deleteUser as firebaseDeleteUser,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithPopup,
} from "firebase/auth";
import { auth, db, googleProvider } from "./firebase"; // Import Firestore instance
import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  arrayUnion,
  arrayRemove,
  collection,
} from "firebase/firestore"; // Firestore methods

// Sign up a new user and save initial preferences using email as the document ID
export const signUpUser = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    // Use email as the document ID in Firestore
    await setDoc(doc(db, "users", user.email), {
      id: user.email, // Store email as the user ID
      theme: "dark", // Default theme
      likedMovies: [], // Initially empty liked movies
      isAdmin: false, // Default isAdmin status
      isSiteOwner: false, // Default isSiteOwner status
    });

    return user;
  } catch (error) {
    console.error("Error creating user or writing to Firestore:", error);
    throw error;
  }
};

// Sign in an existing user
export const signInUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user;
  } catch (error) {
    throw error;
  }
};

// Google Sign-In
export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const user = result.user;

    // Use email as the document ID in Firestore
    const userDocRef = doc(db, "users", user.email);
    const userDoc = await getDoc(userDocRef);

    // Save the user to Firestore if it's a new user
    if (!userDoc.exists()) {
      await setDoc(userDocRef, {
        id: user.email, // Use email as the document ID
        theme: "dark", // Default theme
        likedMovies: [], // Initially empty liked movies
        isAdmin: false, // Default isAdmin status
        isSiteOwner: false, // Default isSiteOwner status
      });
    }

    return user;
  } catch (error) {
    console.error("Error signing in with Google:", error);
    throw error;
  }
};

// Function to send password reset email
export const sendResetPasswordEmail = async (email) => {
  if (!email || typeof email !== "string") {
    throw new Error("Invalid email format.");
  }

  try {
    await sendPasswordResetEmail(auth, email); // Ensure only 'auth' and 'email' are passed
    alert("Password reset email sent!");
  } catch (error) {
    throw error;
  }
};

// Verify reset code
export const verifyResetCode = async (code) => {
  try {
    await verifyPasswordResetCode(auth, code);
    return true;
  } catch (error) {
    throw new Error("Invalid or expired reset code.");
  }
};

// Confirm new password
export const confirmNewPassword = async (code, newPassword) => {
  try {
    await confirmPasswordReset(auth, code, newPassword);
  } catch (error) {
    throw new Error("Failed to reset password: " + error.message);
  }
};

// Check if the user is a site owner (they will also be an admin)
export const checkIfSiteOwner = async (userId) => {
  try {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data().isSiteOwner || false : false;
  } catch (error) {
    console.error("Error checking site owner status: ", error);
    return false;
  }
};

// Fetch all users
export const fetchAllUsers = async () => {
  try {
    const usersRef = collection(db, "users");
    const snapshot = await getDocs(usersRef);
    const users = snapshot.docs.map((doc) => ({
      id: doc.id, // Ensure `id` is present
      ...doc.data(),
    }));
    return users;
  } catch (error) {
    console.error("Error fetching users: ", error);
    throw error;
  }
};

// Fetch user preferences (like theme or liked movies) from Firestore
export const getUserPreferences = async (email) => {
  try {
    const docRef = doc(db, "users", email); // Use email as the document ID
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data(); // Return user preferences
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

// Fetch admin status of the user
export const checkIfAdmin = async (userId) => {
  try {
    const docRef = doc(db, "users", userId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data().isAdmin || false : false;
  } catch (error) {
    console.error("Error checking admin status: ", error);
    return false; // Default to false if an error occurs
  }
};

// Fetch after-credits data for a movie
export const getAfterCreditsData = async (movieId) => {
  // Ensure the movieId is a string
  if (typeof movieId !== "string") {
    movieId = movieId.toString(); // Convert movieId to string if it's not already
  }
  const docRef = doc(db, "movies", movieId);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? docSnap.data() : null; // Return data if exists
};

// Save after-credits info in Firestore
export const saveAfterCreditsInfo = async (
  movieId,
  title,
  afterCreditScene1,
  afterCreditScene2
) => {
  try {
    const docRef = doc(db, "movies", movieId);

    // Use the correct field names without the extra "s"
    await setDoc(
      docRef,
      {
        title: title,
        afterCreditScene1: afterCreditScene1, // Correct field
        afterCreditScene2: afterCreditScene2, // Correct field
      },
      { merge: true } // Merge the data with existing fields
    );
  } catch (error) {
    console.error("Error saving after credits info:", error);
    throw error; // Ensure errors are caught and logged in the applyChanges function
  }
};

// Save user theme preferences
export const saveUserTheme = async (email, theme) => {
  try {
    const userRef = doc(db, "users", email); // Use email as the document ID
    await setDoc(userRef, { theme }, { merge: true }); // Update theme only
  } catch (error) {
    throw error;
  }
};

// Save user's liked movies using email
export const saveMovieToFavorites = async (email, movieId) => {
  try {
    const userRef = doc(db, "users", email); // Use email as the document ID
    await updateDoc(userRef, {
      likedMovies: arrayUnion(movieId), // Add the movie ID to the likedMovies array
    });
  } catch (error) {
    throw error;
  }
};

// Remove user's liked movie
export const removeMovieFromFavorites = async (email, movieId) => {
  try {
    const userRef = doc(db, "users", email); // Use email as the document ID
    await updateDoc(userRef, {
      likedMovies: arrayRemove(movieId), // Remove the movie ID from the likedMovies array
    });
  } catch (error) {
    throw error;
  }
};

// Get user's liked movies
export const getUserFavorites = async (email) => {
  try {
    const userRef = doc(db, "users", email); // Use email as the document ID
    const docSnap = await getDoc(userRef);
    return docSnap.exists() ? docSnap.data().likedMovies : []; // Return liked movies array
  } catch (error) {
    throw error;
  }
};

// Clear all user's liked movies
export const clearUserFavorites = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { likedMovies: [] }); // Set the likedMovies array to empty
  } catch (error) {
    throw error;
  }
};

// Sign out a user
export const signOutUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    throw error;
  }
};

// Update the user's password
export const updateUserPassword = async (user, newPassword) => {
  try {
    await updatePassword(user, newPassword);
  } catch (error) {
    throw error;
  }
};

// Delete user account
export const deleteUserAccount = async (user) => {
  try {
    // Delete the user document from Firestore using email
    const userDocRef = doc(db, "users", user.email);
    await deleteDoc(userDocRef);

    // Then, delete the user from Firebase Authentication
    await firebaseDeleteUser(user);
  } catch (error) {
    console.error("Error deleting user account:", error);
    throw error;
  }
};
