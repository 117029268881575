// src/pages/CookiePolicy.js
import React, { useEffect } from "react";
import BannerAd from "../components/BannerAd";

const CookiePolicy = () => {
  useEffect(() => {
    document.title = "Cookie Policy - AfterCreds";
    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Understand how cookies are used on AfterCreds, including usage by Google Analytics and AdSense.";
    document.head.appendChild(metaDescription);

    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  return (
    <div className="font-custom min-h-screen bg-gray-200 dark:bg-gray-900 pt-20 pb-20 sm:pb-0">
      <div className="container mx-auto p-8">
        <h1 className="text-3xl font-semibold mb-6 text-gray-900 dark:text-white">
          Cookie Policy
        </h1>
        <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-300 dark:border-gray-600 shadow-lg overflow-hidden w-full">
          <div className="sm:pl-8 sm:p-4 p-4">
            <p className="text-gray-700 dark:text-gray-300 mb-6">
              Last Updated: 10/13/2024
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              1. What Are Cookies?
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              Cookies are small text files placed on your device by websites you
              visit. They are used to make websites function more efficiently
              and provide information to the website owners.
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              2. How We Use Cookies
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              We use cookies to enhance your experience on AfterCreds. Cookies
              help us keep you logged in, remember your preferences, and serve
              personalized ads through Google AdSense.
            </p>

            <ul className="list-disc list-inside mb-4 text-gray-700 dark:text-gray-300">
              <li>
                Google Analytics – for tracking user behavior and improving the
                platform.
              </li>
              <li>
                Google AdSense – to display relevant ads based on your activity.
              </li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              3. Types of Cookies We Use
            </h2>
            <ul className="list-disc list-inside mb-4 text-gray-700 dark:text-gray-300">
              <li>
                Session Cookies: These are temporary and deleted when you close
                your browser.
              </li>
              <li>
                Persistent Cookies: These remain on your device until they
                expire or you delete them.
              </li>
              <li>
                Third-Party Cookies: These are used by services like Google
                Analytics and Google AdSense.
              </li>
            </ul>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              4. Managing Cookies
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              You can manage or delete cookies from your browser settings.
              However, disabling cookies may impact your experience on the
              platform.
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              5. U.S. vs E.U. Cookies Consent
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              For U.S. users, cookies are used by default for analytics and ads.
              For E.U. users, cookies will only be used once consent is provided
              through the cookie banner. If you are a U.S. user and do not
              agree, please discontinue use of the website.
            </p>

            <h2 className="text-2xl font-semibold mb-4 text-gray-900 dark:text-white">
              6. Changes to This Policy
            </h2>
            <p className="text-gray-700 dark:text-gray-300 mb-4">
              We reserve the right to update this policy from time to time.
              Changes will be posted on this page.
            </p>
          </div>
        </div>
      </div>
      <BannerAd />
    </div>
  );
};

export default CookiePolicy;
