import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth"; // Firebase functions
import { auth } from "../utils/firebase"; // Import Firebase auth instance

const UpdatePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetCode, setResetCode] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // To get the reset code from the URL

  useEffect(() => {
    document.title = "Update Password - AfterCreds";
  }, []);

  // Extract the reset code from the URL
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const oobCode = queryParams.get("oobCode"); // Firebase sends this code as 'oobCode'
    if (oobCode) {
      setResetCode(oobCode);
    } else {
      setError("Invalid or missing reset code.");
    }
  }, [location]);

  // Handle the password reset
  const handlePasswordReset = async () => {
    // Trim the new password and confirm password fields to remove unwanted spaces
    const sanitizedNewPassword = newPassword.trim();
    const sanitizedConfirmPassword = confirmPassword.trim();

    // Add password validation
    if (sanitizedNewPassword.length < 6) {
      setError("Password must be at least 6 characters long.");
      return;
    }

    // Ensure passwords match
    if (sanitizedNewPassword !== sanitizedConfirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      // Verify the reset code
      await verifyPasswordResetCode(auth, resetCode);

      // Confirm the new password
      await confirmPasswordReset(auth, resetCode, sanitizedNewPassword);
      setSuccess(true);
      setError("");
    } catch (error) {
      setError("Failed to reset password: " + error.message);
    }
  };

  return (
    <div className="font-custom flex justify-center items-center h-screen bg-gray-100 dark:bg-gray-900">
      <div className="bg-white p-8 rounded-lg shadow-md w-96 dark:bg-gray-800 text-gray-800 dark:text-gray-200">
        <h1 className="text-xl mb-4">Reset Your Password</h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success ? (
          <>
            <p className="text-green-500">Password reset successfully!</p>
            {/* Add redirection after displaying the success message */}
            {setTimeout(() => {
              navigate("/account"); // Redirect after showing success message
            }, 3000)}
          </>
        ) : (
          <>
            {/* New Password Input */}
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
              className="
    w-full py-2 px-6 mb-4 rounded-lg
    text-gray-700 dark:text-gray-200
    border border-gray-300 dark:border-gray-600 
    bg-white dark:bg-gray-700 
    focus:outline-none focus:ring-4 focus:ring-green-300
    transition-all duration-300 ease-in-out shadow-md
    placeholder-gray-400
  "
              aria-label="Enter your new password"
            />

            {/* Confirm Password Input */}
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password"
              className="
    w-full py-2 px-6 mb-4 rounded-lg
    text-gray-700 dark:text-gray-200
    border border-gray-300 dark:border-gray-600 
    bg-white dark:bg-gray-700 
    focus:outline-none focus:ring-4 focus:ring-green-300
    transition-all duration-300 ease-in-out shadow-md
    placeholder-gray-400
  "
              aria-label="Confirm your new password"
            />

            {/* Update Password Button */}
            <button
              onClick={handlePasswordReset}
              className="
    w-full py-2 px-6 rounded-lg
    text-white text-lg font-semibold 
    bg-gradient-to-r from-green-600 to-green-500
    hover:from-green-500 hover:to-green-400
    active:from-green-700 active:to-green-600
    transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
    shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-green-300
  "
            >
              Update Password
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default UpdatePassword;
