import axios from "axios";

const API_KEY = process.env.REACT_APP_TMDB_API_KEY; // Access the API key from environment variables

// Fetch popular movies
// export const fetchMovies = () =>
//   axios.get("https://api.themoviedb.org/3/movie/now_playing", {
//     params: { api_key: API_KEY },
//   });

// Fetch movies based on a search term
export const fetchMovies = (query) =>
  axios.get("https://api.themoviedb.org/3/search/movie", {
    params: {
      api_key: API_KEY,
      query: query, // Pass the search term here
    },
  });

// Fetch details for a single movie
export const fetchMovieDetails = (id) =>
  axios.get(`https://api.themoviedb.org/3/movie/${id}`, {
    params: { api_key: API_KEY },
  });
