import { useState, useEffect } from "react";
import {
  signOutUser,
  signInUser,
  signUpUser,
  updateUserPassword,
  deleteUserAccount,
  clearUserFavorites,
  sendResetPasswordEmail,
  signInWithGoogle,
} from "../utils/auth"; // Import updateUserPassword and deleteUserAccount
import { auth } from "../utils/firebase"; // Import the Firebase auth instance
import { useNavigate } from "react-router-dom";

const Account = () => {
  const navigate = useNavigate();
  const user = auth.currentUser;

  // States for Auth
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState("");

  // States for Updating Password
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPasswordFields, setShowPasswordFields] = useState(false);

  // States for Deleting Account and Clearing Favorites
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showClearFavoritesModal, setShowClearFavoritesModal] = useState(false);
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);

  useEffect(() => {
    document.title = user
      ? `${user.email} - Account Settings`
      : "Login / Sign Up - AfterCreds";

    const structuredData = {
      "@context": "https://schema.org",
      "@type": user ? "Person" : "WebPage",
      name: user ? user.email : "Login / Sign Up",
      description:
        "Manage your account settings, update your password, or log out.",
    };

    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(structuredData);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [user]);

  // Handle login or sign-up action
  const handleAuth = async () => {
    const sanitizedEmail = email.trim().toLowerCase();
    const sanitizedPassword = password.trim();

    if (!email || !password) {
      setError("Email and password are required.");
      return;
    }
    if (password.length < 6) {
      setError("Password must be at least 6 characters.");
      return;
    }

    try {
      if (isLogin) {
        await signInUser(email, password);
      } else {
        await signUpUser(email, password);
      }
      setError("");
      navigate("/");
    } catch (err) {
      setError("Invalid email or password");
    }
  };

  // Google Sign-In Handler
  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      navigate("/"); // Redirect to home after Google sign-in
    } catch (error) {
      setError(error.message);
    }
  };

  // Clean states after sign out
  const handleSignOut = () => {
    signOutUser();
    setEmail("");
    setPassword("");
    setError("");
    setNewPassword("");
    setConfirmPassword("");
    navigate("/");
  };

  // Handle password update
  const handlePasswordUpdate = async () => {
    if (newPassword.length < 6) {
      setPasswordError("Password must be at least 6 characters long.");
      return;
    }
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    try {
      await updateUserPassword(user, newPassword);
      setPasswordError("");
      setShowPasswordFields(false);
      alert("Password updated successfully!");
    } catch (error) {
      setPasswordError(error.message);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordReset = async () => {
    if (isLoading) return; // Prevent double execution
    setIsLoading(true); // Set loading state to true

    try {
      if (!email || typeof email !== "string") {
        setError("Please enter a valid email address.");
        setIsLoading(false); // Stop loading if validation fails
        return;
      }

      await sendResetPasswordEmail(email.trim());
      setShowForgotPasswordModal(false); // Close modal after email is sent
    } catch (error) {
      setError(
        "Failed to send reset email. Please check if the email is correct or try again later."
      );
    } finally {
      setIsLoading(false); // Stop loading after request is complete
    }
  };

  // Handle account deletion
  const handleDelete = async () => {
    try {
      await deleteUserAccount(user);
      navigate("/"); // Redirect to home page after deletion
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("An error occurred while trying to delete your account.");
    }
  };

  // Handle clearing all favorites
  const handleClearFavorites = async () => {
    try {
      await clearUserFavorites(user.email); // Clear all favorite movies by resetting the array
      setShowClearFavoritesModal(false); // Close modal
      alert("All favorites have been cleared!");
    } catch (error) {
      console.error("Error clearing favorites:", error);
    }
  };

  return (
    <div className="font-custom flex justify-center items-center h-screen bg-gray-200 dark:bg-gray-900">
      <div className="bg-white p-8 rounded-lg border border-gray-300 dark:border-gray-600 shadow-lg w-96 dark:bg-gray-800 text-gray-800 dark:text-gray-200">
        {user ? (
          <>
            {/* Profile Header */}
            <h1 className="text-2xl font-semibold text-center mb-6">
              Account Settings
            </h1>

            {/* User Email - Non-editable */}
            <div className="flex justify-center mb-6">
              <div
                className="
      w-full max-w-xs py-2 px-6 rounded-lg
      text-gray-800 dark:text-gray-200
      bg-blue-100 dark:bg-gray-700
      border border-blue-300 dark:border-gray-600
      shadow-md 
      text-center
    "
              >
                <p className="text-lg font-semibold">{user.email}</p>
              </div>
            </div>

            {/* Separator Line */}
            <div className="border-t border-gray-300 dark:border-gray-600 my-4"></div>

            {/* Update Password Button and Fields */}
            {!showPasswordFields ? (
              <div className="flex justify-center mt-6">
                <button
                  onClick={() => setShowPasswordFields(true)}
                  className="
        w-full max-w-xs py-2 px-6 rounded-lg
        text-white text-lg font-semibold 
        bg-gradient-to-r from-green-600 to-green-500
        hover:from-green-500 hover:to-green-400
        active:from-green-700 active:to-green-600
        transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
        shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-green-300
      "
                >
                  Update Password
                </button>
              </div>
            ) : (
              <div className="mt-6">
                {/* New Password Input */}
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="New Password"
                  className="
    w-full py-2 px-6 mb-4 rounded-lg
    text-gray-700 dark:text-gray-200
    border border-gray-300 dark:border-gray-600 
    bg-white dark:bg-gray-700 
    focus:outline-none focus:ring-4 focus:ring-green-300
    transition-all duration-300 ease-in-out shadow-md
    placeholder-gray-400
  "
                />

                {/* Confirm Password Input */}
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm New Password"
                  className="
    w-full py-2 px-6 rounded-lg
    text-gray-700 dark:text-gray-200
    border border-gray-300 dark:border-gray-600 
    bg-white dark:bg-gray-700 
    focus:outline-none focus:ring-4 focus:ring-green-300
    transition-all duration-300 ease-in-out shadow-md
    placeholder-gray-400
  "
                />
                {passwordError && (
                  <p className="text-red-500">{passwordError}</p>
                )}
                <div className="flex justify-between">
                  <div className="flex justify-center mt-6">
                    <button
                      onClick={handlePasswordUpdate}
                      className="
            w-full max-w-xs py-2 px-6 rounded-lg
            text-white text-lg font-semibold 
            bg-gradient-to-r from-green-600 to-green-500
            hover:from-green-500 hover:to-green-400
            active:from-green-700 active:to-green-600
            transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
            shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-green-300
          "
                    >
                      Update
                    </button>
                  </div>
                  <div className="flex justify-center mt-6">
                    <button
                      onClick={() => {
                        setShowPasswordFields(false);
                        setNewPassword("");
                        setConfirmPassword("");
                        setPasswordError("");
                      }}
                      className="
            w-full max-w-xs py-2 px-6 rounded-lg
            text-white text-lg font-semibold 
            bg-gradient-to-r from-gray-600 to-gray-500
            hover:from-gray-500 hover:to-gray-400
            active:from-gray-700 active:to-gray-600
            transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
            shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-gray-300
          "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Clear All Favorites Button */}
            <div className="flex justify-center mt-6">
              <button
                onClick={() => setShowClearFavoritesModal(true)}
                className="
      w-full max-w-xs py-2 px-6 rounded-lg
      text-white text-lg font-semibold 
      bg-gradient-to-r from-blue-600 to-blue-500
      hover:from-blue-500 hover:to-blue-400
      active:from-blue-700 active:to-blue-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-blue-300
    "
              >
                Clear All Favorites
              </button>
            </div>

            {/* Delete Account Section */}
            <div className="flex justify-center mt-6">
              <button
                onClick={() => setShowDeleteModal(true)}
                className="
      w-full max-w-xs py-2 px-6 rounded-lg
      text-white text-lg font-semibold 
      bg-gradient-to-r from-red-600 to-red-500
      hover:from-red-500 hover:to-red-400
      active:from-red-700 active:to-red-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-red-300
    "
              >
                Delete Account
              </button>
            </div>

            <div className="flex justify-center mt-6">
              <button
                onClick={handleSignOut}
                className="
      w-full max-w-xs py-2 px-6 rounded-lg
      text-white text-lg font-semibold 
      bg-gradient-to-r from-gray-600 to-gray-500
      hover:from-gray-500 hover:to-gray-400
      active:from-gray-700 active:to-gray-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-gray-300
    "
              >
                Log Out
              </button>
            </div>

            {/* Clear All Favorites Confirmation Modal */}
            {showClearFavoritesModal && (
              <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center px-4 sm:px-0">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full">
                  <h2 className="text-lg mb-4 text-gray-900 dark:text-white">
                    Are you sure you want to clear all your favorites? This
                    action cannot be undone.
                  </h2>
                  <div className="flex justify-center space-x-4 mt-4">
                    <button
                      onClick={handleClearFavorites}
                      className="
      px-4 py-2 rounded-lg w-full max-w-xs
      text-white font-semibold 
      bg-gradient-to-r from-blue-600 to-blue-500
      hover:from-blue-500 hover:to-blue-400
      active:from-blue-700 active:to-blue-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-blue-300
    "
                    >
                      Clear Favorites
                    </button>
                    <button
                      onClick={() => setShowClearFavoritesModal(false)}
                      className="
      px-4 py-2 rounded-lg w-full max-w-xs
      text-white font-semibold 
      bg-gray-500 hover:bg-gray-400 active:bg-gray-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-gray-300
    "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* Delete Account Confirmation Modal */}
            {showDeleteModal && (
              <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center px-4 sm:px-0">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full">
                  <h2 className="text-lg mb-4 text-gray-900 dark:text-white">
                    Are you sure you want to delete your account? This action
                    cannot be undone.
                  </h2>
                  <div className="flex justify-center space-x-4 mt-4">
                    <button
                      onClick={handleDelete}
                      className="
      px-4 py-2 rounded-lg w-full max-w-xs
      text-white font-semibold 
      bg-gradient-to-r from-red-600 to-red-500
      hover:from-red-500 hover:to-red-400
      active:from-red-700 active:to-red-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-red-300
    "
                    >
                      Delete Account
                    </button>
                    <button
                      onClick={() => setShowDeleteModal(false)}
                      className="
      px-4 py-2 rounded-lg w-full max-w-xs
      text-white font-semibold 
      bg-gray-500 hover:bg-gray-400 active:bg-gray-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-gray-300
    "
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          // Sign in/Sign up UI
          <>
            <h2 className="text-xl mb-4">{isLogin ? "Login" : "Sign Up"}</h2>
            {/* Email Input */}
            <input
              type="email"
              className="
    w-full py-2 px-6 mb-4 rounded-lg
    text-gray-700 dark:text-gray-200
    border border-gray-300 dark:border-gray-600 
    bg-white dark:bg-gray-700 
    focus:outline-none focus:ring-4 focus:ring-green-300
    transition-all duration-300 ease-in-out shadow-md
    placeholder-gray-400
  "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />

            {/* Password Input */}
            <input
              type="password"
              className="
    w-full py-2 px-6 mb-6 rounded-lg
    text-gray-700 dark:text-gray-200
    border border-gray-300 dark:border-gray-600 
    bg-white dark:bg-gray-700 
    focus:outline-none focus:ring-4 focus:ring-green-300
    transition-all duration-300 ease-in-out shadow-md
    placeholder-gray-400
  "
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />

            {/* Login/Sign Up Button */}
            <button
              className="
    w-full py-2 px-6 rounded-lg
    text-white text-lg font-semibold 
    bg-gradient-to-r from-green-600 to-green-500
    hover:from-green-500 hover:to-green-400
    active:from-green-700 active:to-green-600
    transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
    shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-green-300
  "
              onClick={handleAuth}
            >
              {isLogin ? "Login" : "Sign Up"}
            </button>

            {error && <p className="text-red-500 mt-4">{error}</p>}

            <div className="flex justify-center">
              <hr className="w-1/2 my-3 border-gray-300 dark:border-gray-700" />
            </div>

            {/* Google Sign-In Button */}
            <button
              className="
    flex w-full py-2 px-6 rounded-lg
    text-gray-600 font-semibold
    border border-gray-300 bg-white
    hover:shadow-lg hover:scale-105 transform transition-all duration-300 ease-in-out
    gap-2 items-center justify-center
  "
              onClick={handleGoogleSignIn}
              aria-label="Sign in with Google"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="xMidYMid"
                viewBox="0 0 256 262"
                className="h-6"
              >
                <path
                  fill="#4285F4"
                  d="M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027"
                ></path>
                <path
                  fill="#34A853"
                  d="M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1"
                ></path>
                <path
                  fill="#FBBC05"
                  d="M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782"
                ></path>
                <path
                  fill="#EB4335"
                  d="M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251"
                ></path>
              </svg>
              {isLogin ? "Sign In with Google" : "Sign Up with Google"}
            </button>

            <div className="mt-4 flex justify-between items-center">
              <button
                className="text-blue-500 underline"
                onClick={() => setIsLogin(!isLogin)}
              >
                {isLogin ? "Sign Up" : "Login"}
              </button>
              {/* Forgot Password Link */}
              {isLogin && (
                <button
                  className="text-blue-500 underline"
                  onClick={() => setShowForgotPasswordModal(true)} // Opens modal
                >
                  Forgot Password?
                </button>
              )}

              {/* Forgot Password Confirmation Modal */}
              {showForgotPasswordModal && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center px-4 sm:px-0">
                  <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full">
                    <h2 className="text-lg mb-4 text-gray-900 dark:text-white">
                      Enter your email to receive a password reset link.
                    </h2>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email"
                      className="
    w-full py-3 px-6 mb-4 rounded-lg
    text-gray-700 dark:text-gray-200
    border border-gray-300 dark:border-gray-600 
    bg-white dark:bg-gray-700 
    focus:outline-none focus:ring-4 focus:ring-green-300
    transition-all duration-300 ease-in-out shadow-md
    placeholder-gray-400
  "
                    />
                    {error && <p className="text-red-500 mb-4">{error}</p>}

                    <div className="flex justify-center space-x-4 mt-4">
                      <button
                        onClick={handlePasswordReset}
                        className="
      px-4 py-2 rounded-lg w-full max-w-xs
      text-white font-semibold 
      bg-gradient-to-r from-green-600 to-green-500
      hover:from-green-500 hover:to-green-400
      active:from-green-700 active:to-green-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-green-300
    "
                      >
                        {isLoading ? "Sending..." : "Send Reset Link"}
                      </button>
                      <button
                        onClick={() => setShowForgotPasswordModal(false)}
                        className="
      px-4 py-2 rounded-lg w-full max-w-xs
      text-white font-semibold 
      bg-gray-500 hover:bg-gray-400 active:bg-gray-600
      transition-all duration-300 ease-in-out transform hover:scale-105 active:scale-95
      shadow-lg hover:shadow-xl focus:outline-none focus:ring-4 focus:ring-gray-300
    "
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Account;
