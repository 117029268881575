import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const GoogleAnalytics = () => {
  const location = useLocation();
  const [isUSUser, setIsUSUser] = useState(false);

  useEffect(() => {
    // Check user region
    fetch("https://ipapi.co/country/")
      .then((response) => response.text())
      .then((countryCode) => {
        if (countryCode === "US") {
          setIsUSUser(true);
        }
      })
      .catch((error) => console.error("Error detecting country: ", error));
  }, []);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");

    // For U.S. users, always enable Google Analytics
    if (isUSUser && window.gtag) {
      window.gtag("config", "G-EQFMZ6XKZH", {
        page_path: location.pathname,
      });
    }

    // For E.U. users, enable only if consent is given
    if (!isUSUser && consent === "true" && window.gtag) {
      window.gtag("config", "G-EQFMZ6XKZH", {
        page_path: location.pathname,
      });
    }
  }, [location, isUSUser]);

  return null;
};

export default GoogleAnalytics;
